import { Form } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import { MixPanel } from "~/utils/MixPanel";
import { useTheme } from "~/utils/theme-provider";

type Props = {};

export function ModalLogout({}: Props) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();
  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalLogOut">
        <div className="modal-box relative  pt-12 pb-8 px-8 bg-base-100 flex flex-col">
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("titleModalLogout")}
          </h3>
          <div className="modal-action  mt-8 grid grid-cols-6 gap-6">
            <form
              method="dialog"
              className="col-span-3 sm:flex sm:items-center sm:gap-4"
            >
              <button className="btn btn-primary btn-outline btn-block">
                {t("teamsPage.buttonModalCancelLabel")}
              </button>
            </form>

            <Form
              method="post"
              action="/action/logout"
              className="col-span-3 sm:flex sm:items-center sm:gap-4"
            >
              <MingleSubmitButton
                submitValue="logout"
                label={t("buttonModalLogoutLabel")}
                onClick={() => {
                  MixPanel.track("mft_signOut_tap_button_signOut");
                }}
              />
            </Form>
          </div>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen backdrop-blur-sm"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
