import { Form } from "@remix-run/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MingleInput } from "~/components/form/MingleInput";
import { MingleSubmitButton } from "~/components/form/MingleSubmitButton";
import {
  AccountRoleEnum,
  IUserLoggedIn,
  PreferredPositionEnum,
} from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";
import { useTheme } from "~/utils/theme-provider";
import { ChangeImage } from "~/components/ChangeImage";
import { MingleSelect } from "~/components/form/MingleSelect";
import MingleRadioButton from "~/components/form/MingleRadioButton";

type Props = {
  userLoggedIn?: IUserLoggedIn | null;
};
export default function ModalEditUser({ userLoggedIn }: Props) {
  const { t, i18n } = useTranslation("translation");
  const [theme] = useTheme();

  const [accountRole, setAccountRole] = useState<AccountRoleEnum>(
    AccountRoleEnum[userLoggedIn?.accountRole] || AccountRoleEnum.Player
  );
  const [firstName, setFirstName] = useState<string | undefined>(
    userLoggedIn?.profile?.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(
    userLoggedIn?.profile?.lastName
  );
  const [gender, setGender] = useState<string | undefined>("");
  const [dobInput, setDobInput] = useState<string>(
    new Date(userLoggedIn?.birthday || "").getFullYear() < 1920
      ? new Date().toISOString().substring(0, 10)
      : new Date(userLoggedIn?.birthday || new Date()).toISOString()
  );

  const [preferredPosition, setPreferredPosition] = useState<
    PreferredPositionEnum | undefined
  >(userLoggedIn?.profile?.preferredPosition);
  useEffect(() => {
    if (!userLoggedIn) return;
    else if (
      userLoggedIn.gender === "undefined" ||
      userLoggedIn.gender === "" ||
      !userLoggedIn.gender
    ) {
      setGender("undefined");
      return;
    } else {
      setGender(userLoggedIn?.gender);
    }
  }, [userLoggedIn]);
  const onGenderChange = (value: string) => {
    setGender(value);
  };
  return (
    <>
      {/*modal ad member*/}

      <dialog
        className="modal overflow-y-scroll flex-wrap"
        id="modalEditUser"
        data-theme={theme}
      >
        <div className="modal-box relative pt-12 pb-8 px-2 lg:px-8 bg-base-100 flex flex-col">
          <div className="text-primary m-auto  dark:text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="inline-block w-12 h-12 mb-4 stroke-current"
              viewBox="0 0 24 24"
            >
              <path
                stroke={"currentColor"}
                strokeWidth="2"
                d="m8.189 19.226-3.822.637a.2.2 0 0 1-.23-.23l.637-3.822a.2.2 0 0 1 .056-.108L16.227 4.305a2.452 2.452 0 1 1 3.468 3.468L8.297 19.17a.2.2 0 0 1-.108.056ZM6.407 15.234l3.467 3.467M15.547 4.985l3.468 3.468"
              />
            </svg>
          </div>
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("editUserModal.title")}
          </h3>
          <p className="text-center">{t("editUserModal.introBody")}</p>
          <Form
            method="post"
            reloadDocument={false}
            action={`/action/updateprofile`}
            autoComplete="off"
            encType="multipart/form-data"
          >
            <div className="mt-8 flex flex-col gap-6">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                className="hidden"
              />
              <ChangeImage
                defaultImage={
                  <>
                    {userLoggedIn?.profile?.imageUrl ? (
                      <>
                        <img
                          src={userLoggedIn?.profile?.imageUrl}
                          alt="profile image"
                          className="mask mask-circle w-36 h-auto"
                        />
                      </>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="44"
                        fill="none"
                      >
                        <path
                          fill="#fff"
                          d="M19.563 24.129c6.333-.284 11.4-5.606 11.4-12.065C30.963 5.607 25.615 0 19 0 12.385 0 7.037 5.394 7.037 12.065c0 6.67 5.067 11.71 11.4 12.064C8.022 24.484 0 32.859 0 44h2.815c0-9.935 6.966-17.032 16.185-17.032 9.218 0 16.185 7.097 16.185 17.032H38c0-11.142-8.022-19.516-18.437-19.871ZM9.852 12.135c0-5.11 4.081-9.225 9.148-9.225 5.067 0 9.148 4.116 9.148 9.226 0 5.11-4.081 9.225-9.148 9.225-5.067 0-9.148-4.187-9.148-9.226Z"
                        />
                      </svg>
                    )}
                  </>
                }
              />

              <MingleInput
                name="firstName"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.currentTarget.value);
                }}
                label={t("editUserModal.labelName")}
                autoFocus
                required
              />

              <MingleInput
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.currentTarget.value);
                }}
                label={t("editUserModal.labelLastName")}
                type="text"
                required
              />
              <MingleInput
                name="email"
                value={userLoggedIn?.email}
                disabled={true}
                label={t("editUserModal.labelEmail")}
                type="text"
              />
              <div>
                <label className="flex gap-2 text-xs font-medium mb-1 text-gray-800 dark:text-gray-200 ">
                  {t("editUserModal.labelGender")}
                </label>
                <div className="flex flex-row justify-center items-center gap-4 ">
                  <MingleRadioButton
                    name="gender"
                    label={t("onBoardingPages.genderMaleLabel")}
                    value="male"
                    checked={gender === "male"}
                    isLarge={false}
                    showRadio={true}
                    onChange={(e) => onGenderChange(e.currentTarget.value)}
                  />
                  <MingleRadioButton
                    name="gender"
                    label={t("onBoardingPages.genderFemaleLabel")}
                    value="female"
                    isLarge={false}
                    showRadio={true}
                    checked={gender === "female"}
                    onChange={(e) => onGenderChange(e.currentTarget.value)}
                  />
                  <MingleRadioButton
                    name="gender"
                    label={t("onBoardingPages.genderOtherLabel")}
                    value="other"
                    isLarge={false}
                    showRadio={true}
                    checked={gender === "other"}
                    onChange={(e) => onGenderChange(e.currentTarget.value)}
                  />
                </div>
                {gender === "undefined" && (
                  <span className="text-red-500 text-sm">
                    {t("editUserModal.labelGender")}
                  </span>
                )}
              </div>
              <input type="hidden" name="accountRole" value={accountRole} />
              {/*<MingleSelect*/}
              {/*  isLarge={false}*/}
              {/*  label={t("editUserModal.accountRole")}*/}
              {/*  value={accountRole}*/}
              {/*  name={`accountRole`}*/}
              {/*  onChange={(e) => {*/}
              {/*    setAccountRole(e.target.value as AccountRoleEnum);*/}
              {/*  }}*/}
              {/*  options={[*/}
              {/*    {*/}
              {/*      label: AccountRoleEnum.Player,*/}
              {/*      value: AccountRoleEnum.Player,*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: AccountRoleEnum.Trainer,*/}
              {/*      value: AccountRoleEnum.Trainer,*/}
              {/*    },*/}
              {/*    {*/}
              {/*      label: AccountRoleEnum.Support,*/}
              {/*      value: AccountRoleEnum.Support,*/}
              {/*    },*/}
              {/*    { label: AccountRoleEnum.Fan, value: AccountRoleEnum.Fan },*/}
              {/*  ]}*/}
              {/*/>*/}

              <MingleSelect
                classNames="order-2 lg:order-1"
                name="preferredPosition"
                isLarge={false}
                label={t("editUserModal.labelPreferredPosition")}
                value={preferredPosition}
                onChange={(e) => {
                  setPreferredPosition(
                    e.currentTarget.value as PreferredPositionEnum
                  );
                }}
                options={[
                  { label: PreferredPositionEnum.GK, value: "GK" },
                  { label: PreferredPositionEnum.CB, value: "CB" },
                  { label: PreferredPositionEnum.LB, value: "LB" },
                  { label: PreferredPositionEnum.RB, value: "RB" },
                  {
                    label: PreferredPositionEnum.CDM,
                    value: "CDM",
                  },
                  { label: PreferredPositionEnum.CM, value: "CM" },
                  { label: PreferredPositionEnum.LM, value: "LM" },
                  { label: PreferredPositionEnum.RM, value: "RM" },
                  {
                    label: PreferredPositionEnum.CAM,
                    value: "CAM",
                  },
                  { label: PreferredPositionEnum.LW, value: "LW" },
                  { label: PreferredPositionEnum.RW, value: "RW" },
                  { label: PreferredPositionEnum.ST, value: "ST" },
                ]}
                endIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 16"
                    className="flex-shrink-0 h-4 w-4"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeWidth="2"
                    >
                      <path d="m5 5 2.505-2.505a.7.7 0 0 1 .99 0L11 5M5 11l2.505 2.505a.7.7 0 0 0 .99 0L11 11" />
                    </g>
                  </svg>
                }
              />

              <MingleInput
                name="birthday"
                label={t("editUserModal.labelDateOfBirth")}
                type="date"
                isLarge={false}
                value={dobInput.substring(0, 10)}
                min="1920-01-01"
                required
                max={new Date().toISOString().substring(0, 10)}
                onChange={(e) => {
                  setDobInput(new Date(e.currentTarget.value).toISOString());
                }}
                classNamesInput={"allowtype"}
              />
            </div>
            <div className="modal-action mt-8 grid grid-cols-6 gap-6">
              <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                <button
                  className="btn btn-primary btn-outline btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    window.modalEditUser.close();
                  }}
                >
                  {t("editUserModal.buttonModalCancelLabel")}
                </button>
              </div>
              <div className="col-span-3 sm:flex sm:items-center sm:gap-4">
                <input type="hidden" name="id" value={userLoggedIn?.id} />
                <MingleSubmitButton
                  label={t("editUserModal.buttonEditMember")}
                  submitValue="editUser"
                  disabled={gender === "undefined"}
                  onClick={(e) => {
                    MixPanel.track("mft_editMember_tap_button_save");
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen backdrop-blur-sm"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
