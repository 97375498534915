import { useTranslation } from "react-i18next";
import ThemeToggle from "~/components/ThemeToggle";
import LanguageSelectListItems from "~/components/LanguageSelectListItems";
import { UserData } from "~/routes/_pages";
import { useMatchesData } from "~/utils/useMatchesData";

export default function UserDropDown() {
  const { t } = useTranslation("translation");
  const { loggedInUser } = useMatchesData<UserData>("routes/_pages");
  return (
    <div className="dropdown ml-0 mt-auto dropdown-top ">
      <label
        tabIndex={0}
        className="btn flex md:flex-col gap-2 w-full p-0 font-normal no-underline border-0 bg-transparent hover:bg-transparent focus:bg-transparent"
      >
        {loggedInUser?.profile?.imageUrl ? (
          <img
            alt={loggedInUser?.profile?.firstName}
            src={loggedInUser?.profile?.imageUrl}
            className="w-8 h-8 lg:h-10 lg:w-10 rounded-full object-cover "
          />
        ) : (
          <>
            <div className="w-8 h-8 rounded-full flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-9"
                fill="currentColor"
                stroke="none"
                viewBox="0 0 45 44"
              >
                <path
                  fill="currenColor"
                  d="m36.694 8.426-12.567-5.06a3.14 3.14 0 0 0-2.325 0L9.236 8.426a3.142 3.142 0 0 0-1.98 2.923v8.391a.77.77 0 0 0 0 .189 28.606 28.606 0 0 0 4.022 12.037 28.953 28.953 0 0 0 9.707 8.297l.236.142c.527.312 1.131.47 1.744.455.648 0 1.282-.191 1.822-.55.44-.298.927-.597 1.445-.927a26.503 26.503 0 0 0 8.483-7.417 27.743 27.743 0 0 0 3.958-12.053.642.642 0 0 0 0-.173V11.35a3.144 3.144 0 0 0-1.979-2.923Zm-13.73 29.291c-1.57 0-5.544-3.315-7.853-5.987v-.22a4.713 4.713 0 0 1 2.136-1.493c1.8.828 3.74 1.308 5.718 1.415a14.525 14.525 0 0 0 5.64-1.477c.854.29 1.617.8 2.214 1.477a1.355 1.355 0 0 0 0 .267c-1.288 2.31-6.283 6.018-7.854 6.018Zm9.834-8.752-.125-.142a7.46 7.46 0 0 0-4.336-2.105c-.423.047-.83.187-1.194.408a8.731 8.731 0 0 1-4.178 1.163 9.563 9.563 0 0 1-4.32-1.084 3.47 3.47 0 0 0-.974-.346 6.565 6.565 0 0 0-4.131 1.744 3.733 3.733 0 0 0-.362.346 28.135 28.135 0 0 1-2.78-9.303v-8.313L22.965 6.29l12.567 5.044v8.313a25.968 25.968 0 0 1-2.734 9.319Z"
                />
                <path
                  fill="currenColor"
                  d="M22.965 11.003a7.852 7.852 0 0 0-7.256 4.85 7.86 7.86 0 0 0 5.724 10.713 7.85 7.85 0 0 0 8.062-3.34 7.86 7.86 0 0 0-6.53-12.223Zm0 12.571a4.711 4.711 0 0 1-4.622-5.634 4.715 4.715 0 0 1 6.425-3.435 4.713 4.713 0 0 1 1.53 7.689 4.712 4.712 0 0 1-3.333 1.38Z"
                />
              </svg>
            </div>
          </>
        )}
        <div>
          <div className="text-sm text-right">
            <strong className="block font-medium text-xs w-full whitespace-nowrap text-left mr-auto w-[170px] overflow-ellipsis overflow-hidden">
              {loggedInUser?.profile?.firstName}{" "}
              {loggedInUser?.profile?.lastName}
            </strong>
            <div className="text-gray-500  text-xs w-[170px] whitespace-nowrap text-left mr-auto overflow-ellipsis overflow-hidden">
              {" "}
              {loggedInUser?.email}{" "}
            </div>
          </div>
        </div>

        <svg
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </label>
      <div className="dropdown-content bg-base-100 p-5 rounded-md lg:p-0 w-full">
        <div className="mx-4 lg:hidden">
          <p className="text-sm text-left">
            <strong className="block font-medium ">
              {loggedInUser?.profile?.firstName}{" "}
              {loggedInUser?.profile?.lastName}
            </strong>
            <span className="text-gray-400 dark:text-gray-200">
              {" "}
              {loggedInUser?.email}{" "}
            </span>
          </p>
        </div>
        <ul
          tabIndex={0}
          className="menu menu-vertical p-2 shadow bg-base-100 rounded-box w-full z-20"
        >
          <div className="divider">Homebase</div>
          <li className="flex items-center justify-center :hover:bg-transparent">
            <ThemeToggle showSmall={false} />
          </li>
          <LanguageSelectListItems />
          <div className="divider">User</div>
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                window.modalEditUser.showModal();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeWidth="2"
                  d="m8.189 19.226-3.822.637a.2.2 0 0 1-.23-.23l.637-3.822a.2.2 0 0 1 .056-.108L16.227 4.305a2.452 2.452 0 1 1 3.468 3.468L8.297 19.17a.2.2 0 0 1-.108.056ZM6.407 15.234l3.467 3.467M15.547 4.985l3.468 3.468"
                />
              </svg>
              Edit User Profile
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                e.preventDefault();
                window.modalLogOut.showModal();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M7.715 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v1a1 1 0 1 1-2 0V5Z"
                  clipRule="evenodd"
                />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="m6.008 15.707-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L6.13 11h7.585a1 1 0 1 1 0 2H6.13l1.292 1.293a1 1 0 1 1-1.414 1.414Z"
                  clipRule="evenodd"
                />
              </svg>
              {t("buttonModalLogoutLabel")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
